import React, { FC } from 'react';

import {
  Box,
  Flex,
  Input,
  Card,
  Text,
  Center,
  Button,
  CardBody,
  Divider,
  CardHeader,
  InputGroup,
  InputLeftElement,
  CircularProgress,
  Link,
  Tooltip,
} from '@chakra-ui/react';
import { AiOutlineSearch } from 'react-icons/ai';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import Header from 'components/layouts/Header';

import { SurveysViewProps } from './Surveys.props';

import { Container } from './Surveys.style';
import dataSet from 'data';
import { Survey } from 'types/survey';

const SurveysView: FC<SurveysViewProps> = ({
  page,
  totalPages,
  surveys,
  isLoading,
  handlePageChange,
  handleSurveyClick,
  handleSearchQuery,
}) => {
  return (
    <Container>
      <Header>Modules</Header>
      {/* <Box p="30px">
        <InputGroup maxW="500px">
          <InputLeftElement pointerEvents="none">
            <AiOutlineSearch color="gray.300" />
          </InputLeftElement>
          <Input
            onChange={(e) => handleSearchQuery(e.target.value)}
            type="tel"
            placeholder="Search your modules here"
          />
        </InputGroup>
      </Box> */}

      <Text pt="25px" px="25px">
        Click on a module below, answer some simple questions, and see your
        results... or click on the resources tab to browse our library on your
        own.
      </Text>
      <Flex pt="25px" px="25px" flex={1} gap="25px" flexWrap="wrap">
        {isLoading ? (
          <Center flex={1}>
            <CircularProgress isIndeterminate />
          </Center>
        ) : surveys.length === 0 ? (
          <Center flex={1}>
            <Text>No surveys Available</Text>
          </Center>
        ) : (
          surveys.map((survey, index) => (
            <Card
              key={survey._id + index}
              flex={1}
              minH="120px"
              minW="500px"
              maxW="500px"
              maxH="200px"
              cursor="pointer"
              transition="0.3s all"
              _hover={{ transform: 'scale(1.05)' }}
              _active={{ transform: 'scale(1)' }}
              onClick={handleSurveyClick(survey)}>
              <CardHeader fontWeight="bold">{survey.name || ''}</CardHeader>
              <Divider opacity={0.2} />
              <CardBody>
                <Tooltip
                  p="10px"
                  hasArrow
                  openDelay={500}
                  bg="primary.500"
                  color="#fff"
                  borderRadius="10px"
                  boxShadow="0 0 10px 5px rgba(0,0,0,0.1)"
                  letterSpacing="1px"
                  label={survey.description}>
                  <Text noOfLines={3}>{survey.description || ''}</Text>
                </Tooltip>
              </CardBody>
            </Card>
          ))
        )}
      </Flex>

      {isLoading && surveys.length === 0 ? null : (
        <Flex mt="20px" p="20px" align="center" gap="20px">
          <Button
            variant="outline"
            onClick={() => handlePageChange('previous')}>
            <MdChevronLeft size="22px" />
          </Button>
          <Text>
            {page} / {totalPages}
          </Text>
          <Button variant="outline" onClick={() => handlePageChange('next')}>
            <MdChevronRight size="22px" />
          </Button>
        </Flex>
      )}
    </Container>
  );
};

export default SurveysView;
