import { Flex, Text, Image, Button } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  flex: 1,
  direction: 'column',
  mb: '100px',
});

export const Row = chakraStyled(Flex, {
  w: '100%',
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
});

export const Label = chakraStyled(Text, {
  fontSize: '14px',
});

export const BoldText = chakraStyled(Text, {
  fontWeight: '600',
  fontSize: '18px',
  pl: '20px',
});

export const SurveyTitleText = chakraStyled(Text, {
  fontWeight: '500',
  fontSize: '14px',
  mb: '5px',
});

export const SurveyImage = chakraStyled(Image, {
  src: 'https://t4.ftcdn.net/jpg/03/80/74/79/360_F_380747975_sS1hCVB0qPqFCWBMZ3qJ5xTqH6rtaDBI.jpg',
  w: '350px',
  h: '130px',
  borderTopLeftRadius: '10px',
  borderBottomLeftRadius: '10px',
});

export const ContinueWhereYouLeftFlex = chakraStyled(Flex, {
  width: '480px',
  borderRadius: '10px',
  m: '10px 0px 10px 10px',
  boxShadow: 'xl',
});

export const SeeAllButton = chakraStyled(Button, {
  bg: 'none',
  color: 'blue',
  fontSize: '14px',
  fontWeight: '500',
  _hover: { bg: 'none' },
  height: 'auto',
});
