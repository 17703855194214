import React, {
  FC,
  useRef,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';

import {
  Text,
  Stack,
  Radio,
  Button,
  Center,
  Spinner,
  RadioGroup,
  Flex,
  Tooltip,
} from '@chakra-ui/react';
import { MdAddCircle, MdCheckCircle, MdUpload } from 'react-icons/md';

import JSZip from 'jszip';
import axios from 'axios';

import { OptionsMap } from 'constants/survey';

import Header from 'components/layouts/Header';

// import * as merge from 'pdf-merge';

import { PDFDocument, rgb } from 'pdf-lib';

// import { HTML2PDFOptions, html2pdf } from 'html2pdf-ts';

import {
  ScoreRecord,
  SurveyViewProps,
  ResourceSuggestionsRef,
  ResourceSuggestionsProps,
} from './Survey.props';
import { Resource } from 'types/resource';
import { Option, Question } from 'types/survey';

import { SurveyCard, CardTitle, Container, QuestionCard } from './Survey.style';
import { generateAnswersPdf } from 'api/survey';
import useFetchResources from 'queries/useFetchResources';

const SurveyView: FC<SurveyViewProps> = ({ survey, history }) => {
  const { questions = [] } = survey;
  const [isLoading, setIsLoading] = useState(false);
  const [answers] = useState(() => {
    const _answers = new Map<number, Option>();

    const savedAnswers = localStorage.getItem(`survey-${survey._id}`);

    if (savedAnswers) {
      const parsedAnswers = JSON.parse(savedAnswers);

      Object.entries(parsedAnswers).forEach(([key, value]: any) => {
        _answers.set(Number(key), value);
      });
    }

    return _answers;
  });

  const resourceSuggestionsRef = useRef<ResourceSuggestionsRef>(null);

  const handleAnswerSelect = useCallback(
    (question: Question, index: number) => (selectedAnswer: string) => {
      const option = question.options.find(
        (o) => o.option.toString() === selectedAnswer,
      );

      if (option) {
        answers.set(index, option);
        resourceSuggestionsRef.current?.triggerRerender?.();
      }
    },
    [],
  );

  useEffect(() => {
    const handleSave = async () => {
      const mappedAnswers: Record<number, Option> = {};

      answers.forEach((value, key) => {
        mappedAnswers[key] = value;
      });

      localStorage.setItem(
        `survey-${survey._id}`,
        JSON.stringify(mappedAnswers),
      );

      await new Promise((res) => setTimeout(res, 100));
    };

    window.addEventListener('beforeunload', handleSave);

    return () => {
      window.removeEventListener('beforeunload', handleSave);
    };
  }, []);

  const generateSurveyPDF = async () => {
    const element = document.getElementById('survey-wrapper');

    const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Document</title>
        <style>
        .chakra-card {
          background-color: #ffffff;
          border: 1px solid #ccc;
          border-radius: 8px;
          padding: 20px;
          margin: 20px;
        }
        .chakra-text {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .css-35ezg3 {
          font-size: 16px;
          color: #333;
          margin-bottom: 20px;
        }
        .chakra-radio-group {
          display: flex;
          flex-direction: column;
        }
        .chakra-radio {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          cursor: pointer;
        }
      
        button {
          padding: 10px;
          background-color: #007bff;
          color: #fff;
          border: none;
          border-radius: 4px;
          visibility: hidden;
          cursor: pointer;
        }
        </style>
      </head>
      <body>
        ${element?.outerHTML}
         <script>
          document.addEventListener('DOMContentLoaded', function () {
            var elements = document.querySelectorAll('*');
            elements.forEach(function (element) {
              element.removeAttribute('style');
            });
          });
        </script>
      </body>
    </html>
  `;

    try {
      const data = await generateAnswersPdf(htmlContent);
      const binaryData = atob(data.item);

      // Convert the binary data to a Uint8Array
      const uint8Array = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }

      // Create a Blob from the Uint8Array
      const pdfBlob = new Blob([uint8Array], { type: 'application/pdf' });

      return pdfBlob; // Create a Blob URL
    } catch (error) {
      return null;
    }
  };

  async function mergePDFs(blobInfo: { blob: Blob; fileName: string }[]) {
    const mergedPdf = await PDFDocument.create();

    for (const { blob } of blobInfo) {
      const pdfBytes = await blob.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes);

      const copiedPages = await mergedPdf.copyPages(
        pdfDoc,
        pdfDoc.getPageIndices(),
      );
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
    }

    const mergedPdfBytes = await mergedPdf.save();

    const mergedBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
    // Trigger download
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(mergedBlob);
    downloadLink.download = `Survey ${survey.name} (resources).pdf`;
    downloadLink.click();
    return mergedBlob;
  }

  const downloadFilesAsZip = async (urls: string[]): Promise<void> => {
    if (urls.length === 0) {
      return;
    }

    setIsLoading(true);
    const blobInfo: { blob: Blob; fileName: string }[] = [];
    const zip = new JSZip();

    const surveyPdfBlob = await generateSurveyPDF();

    if (surveyPdfBlob != null) {
      blobInfo.push({ blob: surveyPdfBlob, fileName: 'survey-answers.pdf' });
    }

    try {
      for (const url of urls) {
        // Make a GET request using Axios
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_STORAGE_URL}${url}`,
            { responseType: 'arraybuffer' },
          );

          // Attempt to extract the file name from the content disposition header
          let fileName = response.headers['content-disposition'];
          if (fileName) {
            fileName = fileName.split('filename=')[1];
          } else {
            // If content disposition header is not present, try to extract from the URL
            fileName = url.split('/').pop() || 'file.txt';
          }
          const contentType = response.headers['content-type'];
          const blob = new Blob([response.data], { type: contentType });
          blobInfo.push({ blob, fileName });
        } catch (error) {
          continue;
        }
      }

      mergePDFs(blobInfo);
      setIsLoading(false);
      clearAnswersDataAndGoBack();

      // blobInfo.forEach(({ blob, fileName }) => {
      //   zip.file(fileName, blob);
      // });

      // const content = await zip.generateAsync({ type: 'blob' });
      // saveAs(content, `Survey ${survey.name} (resources).zip`);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const clearAnswersDataAndGoBack = () => {
    answers.clear();
    localStorage.removeItem(`survey-${survey._id}`);
    history.goBack();
  };

  return (
    <Container>
      <Header isBackable>
        Module /{' '}
        <Text as="span" fontWeight="bold">
          {survey.name}
        </Text>
      </Header>

      <Container flexWrap="wrap" direction="row">
        <Container
          id="survey-wrapper"
          gap="25px"
          flex={2}
          minW="300px"
          maxH="calc(100vh - 80px)"
          overflowY="auto"
          p="20px">
          {questions.map((question, index) => {
            return (
              <QuestionCard key={question.question + index}>
                <Text color="#8E8E8E" fontSize="14px">
                  Question {index + 1}/{questions.length}
                </Text>

                <Text fontWeight="600">{question.question}</Text>

                <RadioGroup
                  defaultValue={answers.get(index)?.option?.toString()}
                  onChange={handleAnswerSelect(question, index)}>
                  <Stack>
                    {question.options.map((option, _index) => {
                      const checkedStatus =
                        answers.get(index)?.option?.toString() ===
                        option.option.toString();

                      return (
                        <Radio
                          id={index + '-' + option.option}
                          key={option.option + _index}
                          value={option.option.toString()}>
                          {OptionsMap[option.option]}{' '}
                        </Radio>
                      );
                    })}
                  </Stack>
                </RadioGroup>
              </QuestionCard>
            );
          })}

          <Button
            mt="40px"
            mb="80px"
            width="200px"
            p="20px"
            alignSelf="center"
            isLoading={isLoading}
            onClick={async () => {
              const selectedResources =
                resourceSuggestionsRef.current?.getSelectedResources() || [];

              if (selectedResources?.length === 0) {
                return;
              }
              // await generateSurveyPDF();

              for (let index = 0; index < questions.length; index++) {
                const answer = answers.get(index)?.option?.toString();
                if (answer) {
                  let id = index + '-' + answer;
                  const element = document.getElementById(
                    id,
                  ) as HTMLInputElement;
                  if (element) {
                    element.setAttribute('checked', 'checked');
                  }
                }
              }
              await downloadFilesAsZip(selectedResources);
            }}>
            Submit
          </Button>
        </Container>

        <Container
          minW="300px"
          maxH="calc(100vh - 80px)"
          overflowY="auto"
          p="20px"
          gap="20px">
          <ResourceSuggestions
            questions={questions}
            answers={answers}
            surveyId={survey._id}
            ref={resourceSuggestionsRef}
          />
        </Container>
      </Container>
    </Container>
  );
};

export default SurveyView;

const ResourceSuggestions = forwardRef<
  ResourceSuggestionsRef,
  ResourceSuggestionsProps
>((props, ref) => {
  const { answers, questions, surveyId } = props;

  const [, setState] = useState(false);

  const [selectedResources] = useState(new Map<string, boolean>());

  const [manullyAddedResources] = useState(new Set());

  const { data, isLoading } = useFetchResources({
    module: surveyId,
    page: 1,
    limit: 100,
    search: '',
  });

  const resources = data?.items || [];

  useImperativeHandle(
    ref,
    () => ({
      triggerRerender: () => setState((prev) => !prev),
      getSelectedResources: () => {
        const items = resources
          .filter((r) => selectedResources.get(r._id))
          .map((r) => r.file);

        return items;
      },
    }),
    [resources],
  );

  const filterResources = (_resources: typeof resources) => {
    if (answers.size === 0) {
      return [];
    }

    const categoriesSet = new Set();
    const inverseScore: ScoreRecord = {};
    const categoryWiseScore: ScoreRecord = {};
    const filteredResources = new Set<Resource>();
    const maxScoreForEachCategory: ScoreRecord = {};
    const filteredResourcesWithScore: Array<Resource & { score: number }> = [];

    questions!.forEach((question) => {
      const questionWeight = question?.options?.length || 0;

      categoriesSet.add(question.category);

      if (maxScoreForEachCategory[question.category]) {
        const previousScore = maxScoreForEachCategory[question.category];

        Object.assign(maxScoreForEachCategory, {
          [`${question.category}`]: previousScore + questionWeight,
        });
      } else {
        Object.assign(maxScoreForEachCategory, {
          [`${question.category}`]: questionWeight,
        });
      }
    });

    answers.forEach((answer, index) => {
      const foundQuestion = questions!.filter((_, i) => i === index);

      if (foundQuestion.length) {
        const question = foundQuestion[0];
        const userAnswer = answer.option;

        const answerWeight = userAnswer ? userAnswer : 0;

        if (categoryWiseScore[question.category]) {
          const previousScore = categoryWiseScore[question.category];
          Object.assign(categoryWiseScore, {
            [`${question.category}`]:
              previousScore + parseInt(answerWeight.toString()),
          });
        } else {
          Object.assign(categoryWiseScore, {
            [`${question.category}`]: parseInt(answerWeight.toString()),
          });
        }
      }
    });

    for (const key in maxScoreForEachCategory) {
      if (
        categoryWiseScore.hasOwnProperty(key) &&
        maxScoreForEachCategory.hasOwnProperty(key)
      ) {
        inverseScore[key] =
          maxScoreForEachCategory[key] - categoryWiseScore[key];
      }
    }

    resources.forEach((resource) => {
      if (resource.fixedScore != null) {
        filteredResources.add(resource);
      } else {
        resource.rule.forEach((rule) => {
          if (categoriesSet.has(rule.questionCategory)) {
            filteredResources.add(resource);
          }
        });
      }
    });

    filteredResources.forEach((resource) => {
      let score = 0;
      if (resource.fixedScore) {
        filteredResourcesWithScore.push({
          ...resource,
          score: resource.fixedScore,
        });
      } else {
        resource.rule.forEach((rule) => {
          if (rule.inverse) {
            if (rule.multilplier) {
              const tempScore = inverseScore[rule.questionCategory] || 0;
              score = rule.multilplier * tempScore;
            } else {
              score += inverseScore[rule.questionCategory] || 0;
            }
          } else {
            if (rule.multilplier) {
              const tempScore = categoryWiseScore[rule.questionCategory] || 0;
              score = rule.multilplier * tempScore;
            } else {
              score += categoryWiseScore[rule.questionCategory] || 0;
            }
          }
        });
        filteredResourcesWithScore.push({
          ...resource,
          score: Number(score / resource.denominator) * 100,
        });
      }
    });

    //find max score
    let maxScore = Number.NEGATIVE_INFINITY;

    for (let i = 1; i < filteredResourcesWithScore.length; i++) {
      maxScore = Math.max(filteredResourcesWithScore[i].score, maxScore);
    }

    if (maxScore > 0) {
      filteredResourcesWithScore.forEach((resource) => {
        if (resource.fixedScore) {
          const percent = resource.fixedScore * 100;
          resource.score = (maxScore * percent) / 100;
        }
      });
    }

    filteredResourcesWithScore.sort((current, next) => {
      return next.score - current.score;
    });

    if (selectedResources.size === 0) {
      resources.forEach((r) => {
        selectedResources.set(r._id, false);
      });
    }

    //this is to calcualte 70 percent of the maxScore
    let scoreMargin70 = (maxScore * 70) / 100;
    let scoreMargin30 = (maxScore * 30) / 100;

    //this is to handle unselection of resources if it goes below top, it also handles manually added resource, if it is manually added it will not remove added resource
    for (let i = 0; i < filteredResourcesWithScore.length; i++) {
      let resource = filteredResourcesWithScore[i];
      let resourceId = resource._id;
      let score = resource.score;
      if (score >= scoreMargin70) {
        resource.cardColor = '#d4ffd4';
        selectedResources.set(resourceId, true);
      } else {
        if (!manullyAddedResources.has(resourceId)) {
          selectedResources.set(resourceId, false);
        }
      }

      if (score <= scoreMargin30) {
        resource.cardColor = '#ffd4d4';
      }
      if (score > scoreMargin30 && score < scoreMargin70) {
        resource.cardColor = '#ffefd4';
      }
    }

    selectedResources.set(filteredResourcesWithScore[0]?._id, true);
    return filteredResourcesWithScore;
  };

  const handleAddResource = (_resource: (typeof resources)[number]) => {
    if (selectedResources.get(_resource._id)) {
      selectedResources.set(_resource._id, false);
      //keeps track of mannully removed resources
      manullyAddedResources.delete(_resource._id);
    } else {
      selectedResources.set(_resource._id, true);
      //keeps track of mannully added resources
      manullyAddedResources.add(_resource._id);
    }
    setState((prev) => !prev);
  };

  return (
    <>
      <Text fontSize="18px" fontWeight="600">
        Resource Suggestions
      </Text>
      {isLoading ? (
        <Center py="100px">
          <Spinner size="lg" />
        </Center>
      ) : (
        <>
          {filterResources(resources).map((resource, index) => {
            const isResourceAdded = selectedResources.get(resource._id);
            const isAddedManually = manullyAddedResources.has(resource._id);
            const showResourceHigh = isResourceAdded && !isAddedManually;
            return (
              <SurveyCard bgColor={resource.cardColor ?? ''} key={resource._id}>
                <CardTitle>{resource.title}</CardTitle>
                <Text noOfLines={2}>{resource.description}</Text>
                <Flex
                  mt="15px"
                  align="center"
                  gap="20px"
                  justify="space-between">
                  {showResourceHigh ? (
                    <Tooltip
                      p="10px"
                      hasArrow
                      bg="primary.500"
                      color="#fff"
                      borderRadius="10px"
                      boxShadow="0 0 10px 5px rgba(0,0,0,0.1)"
                      letterSpacing="1px"
                      label={
                        resource?.hoverDialog?.high ||
                        'Description not available'
                      }>
                      <Text fontWeight="700" fontSize="14px">
                        Why is this suggestion so high?
                      </Text>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      p="10px"
                      hasArrow
                      bg="primary.500"
                      color="#fff"
                      borderRadius="10px"
                      letterSpacing="1px"
                      boxShadow="0 0 10px 5px rgba(0,0,0,0.1)"
                      label={
                        resource?.hoverDialog?.low ||
                        'Description not available'
                      }>
                      <Text fontWeight="700" fontSize="14px">
                        Why is this suggestion so low?
                      </Text>
                    </Tooltip>
                  )}
                  <Button
                    w="min-content"
                    alignSelf="flex-end"
                    variant="ghost"
                    colorScheme={isResourceAdded ? 'green' : 'primary'}
                    onClick={() => handleAddResource(resource)}
                    leftIcon={
                      isResourceAdded ? <MdCheckCircle /> : <MdAddCircle />
                    }>
                    {isResourceAdded ? 'Added' : 'Add to report'}
                  </Button>
                </Flex>
              </SurveyCard>
            );
          })}
        </>
      )}
    </>
  );
});
