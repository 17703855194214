import { useQuery } from '@tanstack/react-query';
import { fetchResources } from 'api/resource';
import { FetchResourcePayload } from 'types/resource';

const useFetchResources = (
  payload: FetchResourcePayload = { page: 1, limit: 12, search: '' },
) => {
  const queryKey = ['fetch-all-resources', payload.module];

  return useQuery({
    queryKey: queryKey,
    queryFn: () => fetchResources(payload),
  });
};

export default useFetchResources;
