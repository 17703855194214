import React, { FC } from 'react';

import { Button, Center, Heading, Stack, Text } from '@chakra-ui/react';

import { NotFoundViewProps } from './NotFound.props';

const NotFoundView: FC<NotFoundViewProps> = () => (
  <Center flex={1} dir="column">
    <Stack
      p={10}
      minW="600px"
      align="center"
      borderRadius="10px"
      bg="#f1f1f1"
      shadow="lg">
      <Text color="primary.500" fontSize="4xl" fontWeight="bold">
        Page Not Found
      </Text>
      <Heading fontSize="8xl">404</Heading>
      <Button as="a" href="/dashboard">
        Go to home
      </Button>
    </Stack>
  </Center>
);

export default NotFoundView;
