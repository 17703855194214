import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';

import { motion } from 'framer-motion';

import { chakraStyled } from 'utils';

const MotionFlex = motion(Flex);

export const Container = chakraStyled(MotionFlex as any, {
  direction: 'column',
  gap: '20px',
  h: '100%',
  position: 'fixed',
  left: '0',
  top: '0',
  bottom: '0',
  initial: false,
  width: '240px',
  transition: { duration: 0.3 },
});

export const Nav = chakraStyled(Box, {
  as: 'nav',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  w: 'full',
  h: 'full',
  p: '6',
  bg: 'white',
  color: 'black',
  borderRight: '1px solid rgb(224, 224, 224)',
  overflow: 'hidden',
});

export const Logo = chakraStyled(Image, {
  w: 'full',
  minW: '50px',
  h: '80px',
  objectFit: 'contain',
  borderRadius: '10px',
  bg: '#fff',
  cursor: 'pointer',
  userSelect: 'none',
  draggable: false,
  my: '20px',
  p: '5px',
});

export const LinkItem = chakraStyled(Flex, {
  as: Link,
  align: 'center',
  justify: 'flex-start',
  gap: '10px',
  p: '6px',
  borderRadius: '10px',
  w: '100%',
});

export const Name = chakraStyled(Text, {
  fontSize: '15px',
  fontWeight: '500',
  userSelect: 'none',
  noOfLines: 1,
});
