enum ROUTES {
  // AUTH ROUTE ENTRY
  AUTH_SIGN_UP = 'AUTH_SIGN_UP',
  AUTH_LOGIN = 'AUTH_LOGIN',

  // ADMIN ROUTE ENTRY
  ADMIN_CREATE_SURVEY = 'ADMIN_CREATE_SURVEY',
  ADMIN_ACCOUNT = 'ADMIN_ACCOUNT',
  ADMIN_CATEGORIES = 'ADMIN_CATEGORIES',
  ADMIN_RESOURCES = 'ADMIN_RESOURCES',
  ADMIN_SURVEYS = 'ADMIN_SURVEYS',
  ADMIN_DASHBOARD = 'ADMIN_DASHBOARD',

  // USER ROUTE ENTRY
  USER_SURVEY = 'USER_SURVEY',
  USER_LANDING = 'USER_LANDING',
  USER_ACCOUNT = 'USER_ACCOUNT',
  USER_RESOURCES = 'USER_RESOURCES',
  USER_SURVEYS = 'USER_SURVEYS',
  USER_DASHBOARD = 'USER_DASHBOARD',

  NOT_FOUND = 'NOT_FOUND',
}

export const routesSettings = Object.freeze({
  // AUTH ROUTE SETTINGS ENTRY
  [ROUTES.AUTH_SIGN_UP]: {
    name: 'SignUp',
    path: '/signup',
    getPath: () => `/signup`,
  },
  [ROUTES.AUTH_LOGIN]: {
    name: 'Login',
    path: '/login',
    getPath: () => `/login`,
  },

  // ADMIN ROUTE SETTINGS ENTRY
  [ROUTES.ADMIN_CREATE_SURVEY]: {
    name: 'CreateSurvey',
    path: '/admin/surveys/create',
    getPath: () => `/admin/surveys/create`,
  },
  [ROUTES.ADMIN_ACCOUNT]: {
    name: 'Account',
    path: '/admin/account',
    getPath: () => `/admin/account`,
  },
  [ROUTES.ADMIN_CATEGORIES]: {
    name: 'Categories',
    path: '/admin/categories',
    getPath: () => `/admin/categories`,
  },
  [ROUTES.ADMIN_RESOURCES]: {
    name: 'Resources',
    path: '/admin/resources',
    getPath: () => `/admin/resources`,
  },
  [ROUTES.ADMIN_SURVEYS]: {
    name: 'Surveys',
    path: '/admin/surveys',
    getPath: () => `/admin/surveys`,
  },
  [ROUTES.ADMIN_DASHBOARD]: {
    name: 'Dashboard',
    path: '/admin/dashboard',
    getPath: () => `/admin/dashboard`,
  },

  // USER ROUTE SETTINGS ENTRY
  [ROUTES.USER_SURVEY]: {
    name: 'Survey',
    path: '/surveys/:surveyId',
    getPath: () => `/surveys/:surveyId`,
  },
  [ROUTES.USER_LANDING]: {
    name: 'Landing',
    path: '/',
    getPath: () => `/`,
  },
  [ROUTES.USER_ACCOUNT]: {
    name: 'Account',
    path: '/account',
    getPath: () => `/account`,
  },
  [ROUTES.USER_RESOURCES]: {
    name: 'Resources',
    path: '/resources',
    getPath: () => `/resources`,
  },
  [ROUTES.USER_SURVEYS]: {
    name: 'Surveys',
    path: '/surveys',
    getPath: () => `/surveys`,
  },
  [ROUTES.USER_DASHBOARD]: {
    name: 'Dashboard',
    path: '/dashboard',
    getPath: () => `/dashboard`,
  },

  // Not Found
  [ROUTES.NOT_FOUND]: {
    name: 'Not Found',
    path: '/*',
    getPath: () => '/*',
  },
});

export default ROUTES;
