import React, { useCallback, useState } from 'react';

import { fetchResources } from 'api/resource';
import { useQuery } from '@tanstack/react-query';

import { ResourcesProps } from './Resources.props';

import ResourcesView from './Resources.view';

import _debounce from 'lodash/debounce';

const ResourcesContainer = (props: ResourcesProps) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const queryKey = ['fetch-resources', page];
  const queryFn = () => fetchResources({ page, limit: 12, search });

  const { data, isFetching, refetch } = useQuery(queryKey, queryFn);

  const resources = data?.items || [];
  const totalPages = data?.totalPages || 0;

  const handlePageChange = (type: 'next' | 'previous') => {
    if (type === 'previous') {
      if (page <= 1) {
        return;
      }
      setPage(page - 1);
    } else if (type === 'next') {
      if (totalPages <= page) {
        return;
      }
      setPage(page + 1);
    }
  };

  const handleSearchDebounced = useCallback(
    _debounce((query) => {
      // Your logic for handling the search query, e.g., making an API call
      setSearch(query);
      refetch();
    }, 200), // 300 milliseconds debounce time (adjust as needed)
    [],
  );

  const handleSearchQuery = useCallback((query: string): (() => void) => {
    // Additional logic if needed
    handleSearchDebounced(query);
    return () => {};
  }, []);

  return (
    <>
      <ResourcesView
        page={page}
        totalPages={totalPages}
        resources={resources}
        isLoading={isFetching}
        handlePageChange={handlePageChange}
        handleSearchQuery={handleSearchQuery}
      />{' '}
    </>
  );
};

export default ResourcesContainer;
