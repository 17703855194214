import React, { FC } from 'react';

import {
  Stack,
  VStack,
  Drawer,
  DrawerBody,
  IconButton,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  useMediaQuery,
  Button,
  Box,
} from '@chakra-ui/react';

import { TbNotes } from 'react-icons/tb';
import { FaUserAlt } from 'react-icons/fa';
import { MdDashboard, MdFolderSpecial, MdLogout, MdMenu } from 'react-icons/md';

import { $session } from 'stores';

import { routesSettings } from 'constants/routes';

import logo from 'assets/onboarding/logo.png';

import { useHistory, useLocation } from 'react-router-dom';

import { UserSideBarProps } from './UserSideBar.props';

import { LinkItem, Logo, Container, Name, Nav } from './UserSideBar.style';
import VerticalLogo from 'components/icons/VerticalLogo';

const QUERY = '(max-width: 1000px)';

const ITEMS = [
  // {
  //   name: 'Dashboard',
  //   path: routesSettings.USER_DASHBOARD.path,
  //   Icon: MdDashboard,
  // },
  {
    name: 'Modules',
    path: routesSettings.USER_DASHBOARD.path,
    Icon: MdDashboard,
  },
  {
    name: 'Resources',
    path: routesSettings.USER_RESOURCES.path,
    Icon: MdFolderSpecial,
  },
  // {
  //   name: 'Account',
  //   path: routesSettings.USER_ACCOUNT.path,
  //   Icon: FaUserAlt,
  // },
];

const UserSideBarView: FC<UserSideBarProps> = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [isMobile] = useMediaQuery(QUERY);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const goToLandingPage = () => {};

  const Logo = () => {
    return (
      <Box
        cursor="pointer"
        onClick={() => {
          history.push('/');
        }}>
        <VerticalLogo width={120} />
      </Box>
    );
  };

  if (isMobile) {
    return (
      <>
        <IconButton
          w="20px"
          position="fixed"
          top={5}
          right={4}
          aria-label="ham-menu"
          bg={'blue.500'}
          icon={<MdMenu />}
          onClick={onOpen}
        />
        <Drawer size="xs" placement="right" onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent maxW="250px">
            <DrawerBody>
              <Stack h="100%" justify="space-between">
                <Logo />
                <VStack flex={1} mt="20px" gap="20px">
                  {ITEMS.map((item) => {
                    const isCurrent = item.path.includes(location.pathname);
                    return (
                      <LinkItem
                        key={item.name}
                        bg={isCurrent ? 'rgb(232, 232, 232)' : 'white'}
                        color={isCurrent ? 'black' : 'black'}
                        onClick={() => {
                          history.push(item.path as string);
                        }}>
                        <item.Icon size="20px" />
                        {isCurrent ? (
                          <Name fontSize="16px" fontWeight="900">
                            {item.name}
                          </Name>
                        ) : (
                          <Name>{item.name}</Name>
                        )}
                      </LinkItem>
                    );
                  })}
                </VStack>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    );
  }

  return (
    <Container position="relative">
      <Container>
        <Nav bgColor="#FEF4EA">
          <Logo />
          <VStack flex={1} width="180px" mt="20px" gap="20px">
            {ITEMS.map((item) => {
              const isCurrent = item.path.includes(location.pathname);
              return (
                <LinkItem
                  key={item.name}
                  bg={isCurrent ? 'rgb(232, 232, 232)' : 'white'}
                  color={isCurrent ? 'black' : 'black'}
                  onClick={() => {
                    history.push(item.path as string);
                  }}>
                  <item.Icon size="20px" />
                  {isCurrent ? (
                    <Name fontSize="16px" fontWeight="900">
                      {item.name}
                    </Name>
                  ) : (
                    <Name>{item.name}</Name>
                  )}
                </LinkItem>
              );
            })}
          </VStack>
        </Nav>
      </Container>
    </Container>
  );
};

export default UserSideBarView;
