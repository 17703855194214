import { Options } from 'types/survey';

export const OptionsMap: Record<Options, string> = {
  1: 'Strongly Agree',
  2: 'Agree',
  3: 'Slightly Agree',
  4: 'No Opinion',
  5: 'Slightly Disagree',
  6: 'Disagree',
  7: 'Strongly Disagree',
};
