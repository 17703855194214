import $user from 'stores/user/store';
import { DashboardProps } from './Dashboard.props';

import DashboardView from './Dashboard.view';

const DashboardContainer = (props: DashboardProps) => {
  return <DashboardView />;
};

export default DashboardContainer;
