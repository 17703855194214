import { Box, Flex, Image, Text, Button } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  flex: 1,
  direction: 'column',
});

export const WelcomeBackText = chakraStyled(Text, {
  p: '20px',
  fontWeight: '600',
  fontSize: '20px',
});

export const ContinueWhereYouLeftText = chakraStyled(Text, {
  pl: '20px',
  fontWeight: '600',
  fontSize: '16px',
});

export const SurveyTitleText = chakraStyled(Text, {
  fontWeight: '500',
  fontSize: '14px',
  mb: '5px',
});

export const SurveyImage = chakraStyled(Image, {
  src: 'https://t4.ftcdn.net/jpg/03/80/74/79/360_F_380747975_sS1hCVB0qPqFCWBMZ3qJ5xTqH6rtaDBI.jpg',
  w: '350px',
  h: '130px',
  borderTopLeftRadius: '10px',
  borderBottomLeftRadius: '10px',
});

export const ResourceImage = chakraStyled(Image, {
  src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCZgrA0BoAlXc8f0BTyQhAJLUPrNt9e2iz9A&usqp=CAU',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
});

export const ResourceBox = chakraStyled(Box, {
  width: '300px',
  height: '320px',
  m: '10px 0px 10px 10px',
  boxShadow: 'xl',
  borderRadius: '10px',
});

export const SeeAllButton = chakraStyled(Button, {
  bg: 'none',
  color: 'blue',
  fontSize: '14px',
  fontWeight: '500',
  _hover: { bg: 'none' },
});

export const ContinueWhereYouLeftFlex = chakraStyled(Flex, {
  width: '480px',
  borderRadius: '10px',
  m: '10px 0px 10px 10px',
  boxShadow: 'xl',
});
