import React, { FC } from 'react';

import { Box, Button, Flex, Image } from '@chakra-ui/react';

import { LandingViewProps } from './Landing.props';

import topRightImage from 'assets/logo/TopRightVector.jpg';
import {
  Logo,
  Wrapper,
  Container,
  HeaderText,
  WelcomeText,
  USDLogoFrame,
} from './Landing.style';
import HorizontalLogo from 'components/icons/HorizontalLogo';
import { Title } from 'components/layouts/AnimatedLoading/AnimatedLoadingStyles';

const LandingView: FC<LandingViewProps> = ({ goToDashboard }) => {
  const openLinkInNewTab = () => {
    const link =
      'https://www.sandiego.edu/peace/institute-for-peace-justice/initiatives/face-peace.php';
    // Open a new tab with the specified link
    window.open(link, '_blank');
  };

  return (
    <Container>
      <Flex
        width="100%"
        flexDirection={{
          base: 'column',
          md: 'row',
        }}
        justifyContent="space-between">
        <Box
          width="100%"
          bg="#FEF4EA"
          flex={1}
          padding={20}
          gap="20px"
          justifyContent={'start'}>
          <Box marginLeft={-2}>
            <HorizontalLogo height={56} />
          </Box>
          <Flex mb="30px" justifyContent="space-between">
            <Title textDecoration="underline">Inclusive Partnerships</Title>
            <Title textDecoration="underline">Durable Networks</Title>
          </Flex>
          <WelcomeText fontSize={'16px'}>
            Communication technology has opened up the world to peace and
            justice. Practitioners can convene groups and build consensus with
            colleagues around the world at the click of button. Yet as we deepen
            our digital engagement, we recognize that distanced relationships
            often lack the depth and trust that encourages real collaboration
            when the stakes are high.
          </WelcomeText>

          <WelcomeText fontSize={'16px'}>
            The FACE Peace Design Tool puts the insights to manage this balance
            at your fingertips. Answer a few questions and the tool will direct
            you to the most relevant best practices and insights for your
            program. With over 100 best practices gathered from more than a
            dozen industries, you're sure to find something to make your
            programs more equitable and durable.
          </WelcomeText>

          <Button onClick={goToDashboard}>GET STARTED</Button>
        </Box>
        <Box width="100%" bg="#F7F3ED" flex={1} padding={20}>
          <Image height={60} src={topRightImage} />
          <Flex justifyContent="start" mb="20px">
            <HeaderText textAlign={'start'}>About FACE Peace</HeaderText>
          </Flex>
          <WelcomeText fontSize={'16px'}>
            At the height of the COVID pandemic, work in peace and development
            moved quickly online. While our field quickly learned to get by, and
            in some cases thrive, with hybrid collaborations, the pandemic
            offered us little chance to evaluate our methods. We launched FACE
            Peace to help humanitarians make evidence-based decisions about
            collaboration design and communication technology. To learn more
            about FACE Peace or read our publications, click
            <a
              style={{
                color: '#0c3655',
                fontWeight: '800',
              }}
              href="https://www.sandiego.edu/peace/institute-for-peace-justice/initiatives/face-peace.php"
              target="_blank">
              {' '}
              HERE
            </a>
          </WelcomeText>
          <WelcomeText fontSize={'16px'}>
            To discuss this Design Tool and our other products, please email
            John Porten at jporten@sandiego.edu
          </WelcomeText>

          <WelcomeText fontSize={'16px'}>
            The Kroc Institute for Peace and Justice is an applied research
            institute at the University of San Diego's Kroc School of Peace
            Studies. Its mission is to co-create learning with peacemakers -
            learning that is deeply grounded in the lived experience of
            peacemakers around the world, that is made rigorous by our place
            within a university ecosystem and that is immediately and
            practically applied by peacemakers to end cycles of violence. Learn
            more.
            <a
              style={{
                color: '#0c3655',
                fontWeight: '800',
              }}
              href="https://www.sandiego.edu/peace/institute-for-peace-justice/"
              target="_blank">
              {' '}
              HERE
            </a>
          </WelcomeText>
          <Flex justifyContent="end">
            <USDLogoFrame onClick={openLinkInNewTab} />
          </Flex>
        </Box>
      </Flex>
    </Container>
  );
};

export default LandingView;
