import { extendTheme, theme as defaultTheme } from '@chakra-ui/react';
import '@fontsource/poppins';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

export const theme = extendTheme({
  ...defaultTheme,
  colors: {
    primary: {
      100: '#FCF1E8',
      200: '#F9E4D2',
      300: '#F7D6BB',
      400: '#F4C8A4',
      500: '#F1BA8C',
      600: '#E99149',
      700: '#CD6A19',
      800: '#884711',
      900: '#442308',
    },
  },
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
    mono: 'Poppins',
  },
  fontSizes: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '24px',
    '2xl': '26px',
    '3xl': '28px',
  },
  lineHeights: {
    normal: 'normal',
    none: '1',
    shorter: '1.25',
    short: '1.375',
    base: '1.5',
    tall: '1.625',
    taller: '2',
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  components: {
    ...defaultTheme.components,
    Button: {
      ...defaultTheme.components.Button,
      defaultProps: {
        ...defaultTheme.components.Button.defaultProps,
        colorScheme: 'primary',
      },
    },
  },
});
