import React, { useCallback, useState } from 'react';

import { fetchSurveys } from 'api/survey';
import { useQuery } from '@tanstack/react-query';

import { Survey } from 'types/survey';
import { SurveysProps } from './Surveys.props';
import _debounce from 'lodash/debounce';

import SurveysView from './Surveys.view';
import dataSet from 'data';

const SurveysContainer = (props: SurveysProps) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const queryKey = ['fetch-surveys', page];
  const queryFn = () => fetchSurveys({ page, limit: 12, search });

  const { data, isFetching, refetch } = useQuery(queryKey, queryFn);

  const surveys = data?.items || [];
  const totalPages = data?.totalPages || 0;

  const handlePageChange = (type: 'next' | 'previous') => {
    if (type === 'previous') {
      if (page <= 1) {
        return;
      }

      setPage(page - 1);
    } else if (type === 'next') {
      if (totalPages <= page) {
        return;
      }

      setPage(page + 1);
    }
  };

  const handleSurveyClick = useCallback(
    (survey: Survey) => () => {
      if (survey._id == '') return;
      props.history.push(`/surveys/${survey._id}`, survey);
    },
    [],
  );

  const handleSearchDebounced = useCallback(
    _debounce((query) => {
      // Your logic for handling the search query, e.g., making an API call
      setSearch(query);
      refetch();
    }, 200), // 300 milliseconds debounce time (adjust as needed)
    [],
  );

  const handleSearchQuery = useCallback((query: string): (() => void) => {
    // Additional logic if needed
    handleSearchDebounced(query);
    return () => {};
  }, []);

  return (
    <SurveysView
      page={page}
      totalPages={totalPages}
      surveys={surveys}
      isLoading={isFetching}
      handlePageChange={handlePageChange}
      handleSurveyClick={handleSurveyClick}
      handleSearchQuery={handleSearchQuery}
    />
  );
};

export default SurveysContainer;
