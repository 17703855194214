import React, { FC } from 'react';

import { MdArrowBack } from 'react-icons/md';
import { Box, Button, Flex, Text } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';

import { HeaderProps } from './Header.props';

const HeaderView: FC<HeaderProps> = ({
  children,
  isCentered,
  isBackable = false,
  RightButton = null,
  subtitle,
}) => {
  const history = useHistory();

  return (
    <Flex
      p="25px"
      bg="#fff"
      maxH={{
        base: '100px',
        md: '80px',
      }}
      align="center"
      gap="20px"
      borderBottom={'1px solid rgb(224, 224, 224)'}>
      {isBackable && (
        <Button
          size="sm"
          variant="ghost"
          leftIcon={<MdArrowBack size="25px" color="#000" />}
          onClick={history.goBack}
        />
      )}
      <Box>
        <Text
          w="100%"
          fontSize="22px"
          userSelect="none"
          textAlign={isCentered ? 'center' : 'left'}>
          {children}
        </Text>
        {subtitle && (
          <Text
            fontSize={{
              base: '12px',
              md: '14',
            }}
            w={{
              base: '90%',
              md: '100%',
            }}>
            {subtitle}
          </Text>
        )}
      </Box>

      {RightButton}
    </Flex>
  );
};

export default HeaderView;
