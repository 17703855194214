import { Flex, Image, Text } from '@chakra-ui/react';

import logo from 'assets/onboarding/logo.png';
import usdLogo from 'assets/onboarding/USD-Kroc-Logo.webp';

import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  flex: 1,
  direction: { base: 'column-reverse', md: 'row' },
});

export const Wrapper = chakraStyled(Flex, {
  p: { base: '20px', md: '4vw' },
  direction: 'column',
  gap: '10px',
});

export const Row = chakraStyled(Flex, {
  align: 'center',
  gap: '20px',
});

export const Logo = chakraStyled(Image, {
  src: logo,
  mt: '60px',
  display: { base: 'flex', md: 'block' },
});

export const WelcomeText = chakraStyled(Text, {
  fontWeight: '500',
  fontSize: '18px',
  mb: '30px',
});

export const HeaderText = chakraStyled(Text, {
  align: 'center',
  fontWeight: 'bold',
  fontSize: {
    base: '14px',
    sm: '16px',
    md: '20px',
    lg: '24px',
    xl: '30px',
  },
});

export const PlainText = chakraStyled(Text, {
  m: '20px 0px 20px 0px',
  fontSize: '12px',
});

export const USDLogoFrame = chakraStyled(Image, {
  src: usdLogo,
  objectFit: 'cover',
  justify: 'center',
  align: 'center',
  direction: 'column',
  maxHeight: '300px',
  w: '100px',
  h: '100px',
  cursor: 'pointer',
  transition: 'transform 0.3s ease-in-out',
  borderRadius: '20px',
  _hover: {
    transform: 'scale(1.01)',
  },
});
