import React, { FC } from 'react';

import { Flex } from '@chakra-ui/react';

import { withMotion } from 'utils';

import { Route, Switch, useLocation } from 'react-router-dom';

import ROUTES, { routesSettings } from 'constants/routes';

import UserSideBar from 'components/core/UserSideBar';

// IMPORT ROUTE
import Survey from 'routes/user/Survey';
import Landing from 'routes/user/Landing';
// import Account from 'routes/user/Account';
import Resources from 'routes/user/Resources';
import Surveys from 'routes/user/Surveys';
import Dashboard from 'routes/user/Dashboard';
import NotFound from 'routes/common/NotFound';

const routeConfig = Object.freeze({
  // USER ROUTE ENTRY
  [ROUTES.USER_SURVEY]: {
    component: Survey,
    path: routesSettings.USER_SURVEY.path,
  },
  [ROUTES.USER_LANDING]: {
    component: Landing,
    path: routesSettings.USER_LANDING.path,
  },
  // [ROUTES.USER_ACCOUNT]: {
  //   component: Account,
  //   path: routesSettings.USER_ACCOUNT.path,
  // },
  [ROUTES.USER_RESOURCES]: {
    component: Resources,
    path: routesSettings.USER_RESOURCES.path,
  },
  [ROUTES.USER_SURVEYS]: {
    component: Surveys,
    path: routesSettings.USER_DASHBOARD.path,
  },
  [ROUTES.USER_DASHBOARD]: {
    component: Dashboard,
    path: routesSettings.USER_DASHBOARD.path,
  },

  // this route should be at the last
  [ROUTES.NOT_FOUND]: {
    component: NotFound,
    path: routesSettings.NOT_FOUND.path,
  },
});

const UserRoutes: FC = () => {
  const location = useLocation();
  const isLandingPage = location.pathname === '/';
  const isSurveyPage =
    location.pathname.includes('/surveys/') && location.pathname.length > 15;

  return (
    <Flex flex={1} bg="#fff">
      {isLandingPage || isSurveyPage ? null : <UserSideBar />}
      <Flex bg="#fff" flexGrow={1} flexBasis={0} marginX="auto" overflow="auto">
        <Switch>
          {Object.entries(routeConfig).map(([key, val]) => (
            <Route
              exact
              key={key}
              path={val.path}
              component={withMotion(val.component as any)}
            />
          ))}
        </Switch>
      </Flex>
    </Flex>
  );
};

export default UserRoutes;
