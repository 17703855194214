import { api } from './instance';

import { FetchResourcePayload, Resource } from 'types/resource';
import { ApiListResponse } from 'types/common';

export const fetchResources = async (params: FetchResourcePayload) => {
  const { data } = await api.get<ApiListResponse<Resource>>(`/resource/list`, {
    params,
  });
  return data;
};

export const fetchAllResources = async () =>
  fetchResources({ limit: 12, page: 1, search: '' });
