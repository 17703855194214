import { FC, useMemo } from 'react';

// import { $session } from 'stores';

// import AuthRoutes from './auth';
import UserRoutes from './user';
// import AdminRoutes from './admin';
// import useFetchUser from 'queries/useFetchUser';
// import AnimatedLoadingView from 'components/layouts/AnimatedLoading/AnimatedLoading.view';
// import { Flex } from '@chakra-ui/react';

const RootRoutes: FC = () => {
  // const role = $session.selectors.useRole();
  // const isAuth = $session.selectors.useIsAuth();
  // const { isFetching } = useFetchUser();

  const Routes = useMemo(() => UserRoutes, []);

  return <Routes />;
};

export default RootRoutes;
