import React from 'react';

import { routesSettings } from 'constants/routes';

import { LandingProps } from './Landing.props';

import LandingView from './Landing.view';

const LandingContainer = (props: LandingProps) => {
  const goToDashboard = () => {
    props.history.push(routesSettings.USER_DASHBOARD.path);
  };

  return <LandingView goToDashboard={goToDashboard} />;
};

export default LandingContainer;
