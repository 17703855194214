import { api } from './instance';

import { Survey } from 'types/survey';
import { ApiListResponse } from 'types/common';
import dataSet from 'data';

export const fetchSurveys = async (params: {
  page: number;
  limit: number;
  search: string;
}) => {
  const { data } = await api.get<ApiListResponse<Survey>>('/module/list', {
    params,
  });

  dataSet.upcomingMudules.forEach((survey) => {
    data.items.push(survey);
  });

  return data;
};

export const generateAnswersPdf = async (code: string) => {
  const { data } = await api.post<any>('/resource/generate-answers-pdf', {
    code: encodeURIComponent(code),
  });
  return data;
};
