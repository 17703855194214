import React, { FC } from 'react';

import { Box, Tag, Flex, Text, TagLabel } from '@chakra-ui/react';

import Header from 'components/layouts/Header';

import { DashboardViewProps } from './Dashboard.props';

import {
  Container,
  SurveyImage,
  ResourceBox,
  SeeAllButton,
  ResourceImage,
  WelcomeBackText,
  SurveyTitleText,
  ContinueWhereYouLeftText,
  ContinueWhereYouLeftFlex,
} from './Dashboard.style';

const DashboardView: FC<DashboardViewProps> = (props) => {
  return (
    <Container>
      <Header>Dashboard</Header>
      <WelcomeBackText>Welcome back!</WelcomeBackText>
      {/* <ContinueWhereYouLeftText>
        Continue where you left of
      </ContinueWhereYouLeftText>
      <Flex style={{ flexWrap: 'wrap' }} ml="10px">
        <ContinueWhereYouLeftFlex>
          <SurveyImage />
          <Box m="10px 0px 0px 10px">
            <SurveyTitleText>Survey Title</SurveyTitleText>
            <Text fontSize="13px">
              A survey is a set of questions or prompts designed to gather
              information or opinions from a specific group of people.
            </Text>
            <Tag size="md" bg="orange.100" mt="3px">
              <TagLabel color="brown" fontWeight="600">
                TEAM COLLAB
              </TagLabel>
            </Tag>
          </Box>
        </ContinueWhereYouLeftFlex>
        <ContinueWhereYouLeftFlex>
          <SurveyImage />
          <Box m="10px 0px 0px 10px">
            <SurveyTitleText>Survey Title</SurveyTitleText>
            <Text fontSize="13px">
              A survey is a set of questions or prompts designed to gather
              information or opinions from a specific group of people.
            </Text>
            <Tag size="md" bg="orange.100" mt="3px">
              <TagLabel color="brown" fontWeight="600">
                TEAM COLLAB
              </TagLabel>
            </Tag>
          </Box>
        </ContinueWhereYouLeftFlex>
      </Flex> */}
      <Flex m="20px 30px 0px 20px" justifyContent="space-between">
        <Text fontSize="16px" fontWeight="600">
          Resources
        </Text>
        <SeeAllButton>See all</SeeAllButton>
      </Flex>
      <Flex style={{ flexWrap: 'wrap' }} ml="10px">
        <ResourceBox>
          <ResourceImage />
        </ResourceBox>
        <ResourceBox>
          <ResourceImage />
        </ResourceBox>
        <ResourceBox>
          <ResourceImage />
        </ResourceBox>
        <ResourceBox>
          <ResourceImage />
        </ResourceBox>
      </Flex>
    </Container>
  );
};

export default DashboardView;
