import React, { FC, useCallback } from 'react';

import {
  Box,
  Flex,
  Input,
  Card,
  Text,
  Center,
  Button,
  Tooltip,
  CardFooter,
  CardHeader,
  InputGroup,
  InputLeftElement,
  CircularProgress,
  useToast,
} from '@chakra-ui/react';
import { AiOutlineSearch } from 'react-icons/ai';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import Header from 'components/layouts/Header';

import { ResourcesViewProps } from './Resources.props';

import { Container } from './Resources.style';

const ResourcesView: FC<ResourcesViewProps> = ({
  page,
  totalPages,
  isLoading,
  resources,
  handlePageChange,
  handleSearchQuery,
}) => {
  const toast = useToast();

  const handleViewResource = useCallback(
    (fileName: string) => async () => {
      try {
        const fileUrl = `${process.env.REACT_APP_STORAGE_URL}${fileName}`;
        const response = await fetch(fileUrl);

        if (!response.ok) {
          throw new Error(`Failed to fetch file. Status: ${response.status}`);
        }
        const blob = await response.blob();
        // Open the file in a new tab
        window.open(URL.createObjectURL(blob), '_blank');
      } catch (error) {
        toast({
          title: `Failed to fetch file.`,
          isClosable: true,
          status: 'error',
        });
      }
    },
    [],
  );

  return (
    <Container>
      <Header>Resources</Header>
      <Box p="30px">
        <InputGroup maxW="500px">
          <InputLeftElement pointerEvents="none">
            <AiOutlineSearch color="gray.300" />
          </InputLeftElement>
          <Input
            onChange={(e) => handleSearchQuery(e.target.value)}
            type="tel"
            placeholder="Search your resources here"
          />
        </InputGroup>
      </Box>

      <Flex px="25px" flex={1} gap="25px" flexWrap="wrap">
        {isLoading ? (
          <Center flex={1}>
            <CircularProgress isIndeterminate />
          </Center>
        ) : resources.length === 0 ? (
          <Center flex={1}>
            <Text>No Resources Available</Text>
          </Center>
        ) : (
          resources.map((resource, index) => (
            <Card
              key={resource._id + index}
              flex={1}
              minH="120px"
              minW="500px"
              maxW="500px"
              maxH="220px"
              transition="0.3s all"
              _hover={{ transform: 'scale(1.05)' }}
              _active={{ transform: 'scale(1)' }}>
              <CardHeader fontWeight="bold" noOfLines={2}>
                {resource.title || ''}
              </CardHeader>

              <Flex px="20px" direction="column" gap="5px">
                <Text fontSize="14px" noOfLines={2}>
                  {resource?.description || ''}
                </Text>
                <Tooltip
                  p="20px"
                  hasArrow
                  bg="primary.500"
                  color="#fff"
                  borderRadius="10px"
                  boxShadow="0 0 10px 5px rgba(0,0,0,0.1)"
                  letterSpacing="1px"
                  label={resource?.description || 'Not available'}>
                  <Text fontSize="13px" fontWeight="bold">
                    See More
                  </Text>
                </Tooltip>
              </Flex>
              <Flex flex={1} />
              <CardFooter>
                <Button size="sm" onClick={handleViewResource(resource.file)}>
                  VIEW
                </Button>
              </CardFooter>
            </Card>
          ))
        )}
      </Flex>

      {isLoading && resources.length === 0 ? null : (
        <Flex mt="20px" p="20px" align="center" gap="20px">
          <Button
            variant="outline"
            onClick={() => handlePageChange('previous')}>
            <MdChevronLeft size="22px" />
          </Button>
          <Text>
            {page} / {totalPages}
          </Text>
          <Button variant="outline" onClick={() => handlePageChange('next')}>
            <MdChevronRight size="22px" />
          </Button>
        </Flex>
      )}
    </Container>
  );
};

export default ResourcesView;
function useWindowSize() {
  throw new Error('Function not implemented.');
}
