import { Card, Flex, Text } from '@chakra-ui/react';

import { chakraStyled } from 'utils';

export const Container = chakraStyled(Flex, {
  flex: 1,
  direction: 'column',
});

export const QuestionCard = chakraStyled(Card, {
  w: '100%',
  p: '20px',
  maxW: '1000px',
  alignSelf: 'center',
  borderRadius: '10px',
  shadow: 'lg',
  gap: '10px',
});

export const SurveyCard = chakraStyled(Card, {
  w: '100%',
  p: '20px',
  maxW: '1000px',
  alignSelf: 'center',
  borderRadius: '10px',
  shadow: 'md',
  gap: '10px',
  border: '1px solid #e4e4e4',
});

export const CardTitle = chakraStyled(Text, {
  fontSize: '18px',
  fontWeight: '600',
  color: 'primary.500',
  noOfLines: 2,
});
