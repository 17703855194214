import axios from 'axios';

// import { $session } from 'stores';

const BASE_URL = `${process.env.REACT_APP_API_URL || ''}api/v1`;

const DEFAULT_HEADERS: Record<string, string> = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const instance = axios.create({
  baseURL: BASE_URL,
  headers: DEFAULT_HEADERS,
});

// instance.interceptors.request.use((request) => {
//   const tokens = $session.actions.getTokens();

//   const idToken = tokens?.idToken || '';

//   if (idToken) {
//     request.headers.Authorization = `Bearer ${idToken}`;
//   }

//   return request;
// });

export const api = instance;
