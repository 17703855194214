import { FC } from 'react';
import { IconProp } from './Icons.prop';
import { Image } from '@chakra-ui/react';
import logo from 'assets/logo/Logo.jpg';

const HorizontalLogo: FC<IconProp> = ({ width, height }) => {
  return <Image height={height} src={logo} />;
};

export default HorizontalLogo;
